<template>
  <!-- Universal footer with terms link and copyright notice-->
  <v-footer class="d-flex flex-column" color="transparent" dark>
    <v-divider color="grey" class="my-1"></v-divider>
    <div class="d-flex w-100 align-center px-4">
      <v-spacer></v-spacer>
    </div>

    <div class="px-4 py-2 bg-background text-center w-100">
      <span class="footer-text text-grey">
        42KIT
        是社区驱动的星际公民综合信息数据库与社交平台，不受任何公司或组织控制。
        所有游戏内容和材料的版权均归 Cloud Imperium Rights LLC 和 Cloud Imperium
        Rights Ltd. 所有。Star Citizen®、Squadron 42®、Roberts Space
        Industries® 和 Cloud Imperium® 是 Cloud Imperium Rights LLC
        的参加商标，版权所有。 除非另有说明，否则其他内容可通过
        <a
          href="https://creativecommons.org/licenses/by-sa/4.0/"
          target="_blank"
        >
          知识共享署名-相同
        </a>
        方式共享获得。
      </span>
      <br />
      <span class="footer-text">
        <strong>42KIT</strong> &copy; {{ new Date().getFullYear() }}
      </span>
    </div>
  </v-footer>
</template>
<style lang="scss" scoped>
.footer-text {
  font-size: 0.8rem;
  line-height: 0.5rem;
}
</style>
