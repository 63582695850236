<template>
  <v-app>
    <clientOnly>
      <SiteNavigationLeftDrawer />
    </clientOnly>
    <v-main style="height: 100%" class="d-flex flex-column">
      <SiteAnnouncements />
      <slot />
      <v-spacer />
      <v-divider color="grey"></v-divider>
      <div class="d-flex align-end">
        <SiteFooter v-if="!smAndDown" />
      </div>
    </v-main>
    <clientOnly>
      <SiteBottomNavigation v-if="smAndDown" />
    </clientOnly>
    <v-spacer />
  </v-app>
</template>
<script setup>
import { useDisplay } from "vuetify";
const { smAndDown } = useDisplay();
</script>
