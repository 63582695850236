<template>
  <v-slide-y-transition group>
    <div
      v-for="announcement in announcements"
      :key="announcement.id"
      class="ma-2"
    >
      <v-alert
        variant="tonal"
        border="start"
        :type="announcement.level"
        :value="announcement.status"
        :title="announcement.title"
        closable
        :class="{
          'mb-0 corner-cut-1': announcements.length === 1,
          'mb-2 corner-cut-1': announcements.length > 1,
        }"
        @click:close="closeAnnouncement(announcement.id)"
      >
        <div
          v-dompurify-html="announcement.content"
          class="no-text-decoration"
        />
      </v-alert>
    </div>
  </v-slide-y-transition>
</template>
<script setup>
const announcements = ref([]);
const snackbar = useSnackbar();
// 页面加载后获取当前页面路径的公告
onMounted(async () => {
  const route = useRoute();
  const locationPromise = await useDirectus(
    readItems("announcements", {
      filter: {
        status: { _eq: "true" },
        _or: [
          {
            path: { _eq: route.path },
          },
          {
            path: { _eq: "ALL" },
          },
        ],
      },
    }),
  ).catch(() => {
    snackbar.add({
      type: "error",
      text: "公告获取失败",
    });
  });
  if (locationPromise && locationPromise.length > 0) {
    // announcements.value = data;
    // 从公告列表中移除已关闭的公告
    announcements.value = locationPromise.filter((announcement) =>
      checkAnnouncement(announcement.id),
    );
  } else {
    announcements.value = [];
  }
});

// 监听路由变化，获取当前页面路径的公告
watch(
  () => useRoute().path,
  async (path) => {
    const locationPromise = await useDirectus(
      readItems("announcements", {
        filter: {
          status: { _eq: "true" },
          _or: [
            {
              path: { _eq: path },
            },
            {
              path: { _eq: "ALL" },
            },
          ],
        },
      }),
    ).catch(() => {
      snackbar.add({
        type: "error",
        text: "公告获取失败",
      });
    });

    if (locationPromise && locationPromise.length > 0) {
      // announcements.value = data;
      // 从公告列表中移除已关闭的公告
      announcements.value = locationPromise.filter((announcement) =>
        checkAnnouncement(announcement.id),
      );
    } else {
      announcements.value = [];
    }
  },
);

const closeAnnouncement = (id) => {
  // 检查 localStorage 中储存的已关闭公告列表
  const closedAnnouncements = JSON.parse(
    localStorage.getItem("closedAnnouncements") || "[]",
  );
  // 如果已关闭公告列表中没有当前公告，则添加到已关闭公告列表中
  if (!closedAnnouncements.includes(id)) {
    closedAnnouncements.push(id);
    localStorage.setItem(
      "closedAnnouncements",
      JSON.stringify(closedAnnouncements),
    );
  }
  // 从公告列表中移除当前公告
  announcements.value = announcements.value.filter(
    (announcement) => announcement.id !== id,
  );
};

const checkAnnouncement = (id) => {
  // 检查 localStorage 中储存的已关闭公告列表
  const closedAnnouncements = JSON.parse(
    localStorage.getItem("closedAnnouncements") || "[]",
  );
  // 如果已关闭公告列表中没有当前公告，则返回 true
  return !closedAnnouncements.includes(id);
};
</script>
